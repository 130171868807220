exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-home-about-js": () => import("./../../../src/pages/home/about.js" /* webpackChunkName: "component---src-pages-home-about-js" */),
  "component---src-pages-home-clients-js": () => import("./../../../src/pages/home/clients.js" /* webpackChunkName: "component---src-pages-home-clients-js" */),
  "component---src-pages-home-contact-js": () => import("./../../../src/pages/home/contact.js" /* webpackChunkName: "component---src-pages-home-contact-js" */),
  "component---src-pages-home-press-js": () => import("./../../../src/pages/home/press.js" /* webpackChunkName: "component---src-pages-home-press-js" */),
  "component---src-pages-home-solutions-js": () => import("./../../../src/pages/home/solutions.js" /* webpackChunkName: "component---src-pages-home-solutions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-post-js": () => import("./../../../src/pages/post.js" /* webpackChunkName: "component---src-pages-post-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

