import * as React from "react";
import { Script } from "gatsby";
import '../assets/scss/qranio.scss';

const QrLayout = (props) => {
    const { children } = props;

    return (
        <main id="qr-layout">
            {children}

            <Script src="//cdn.siteblindado.com/aw.js" />
            <Script src="//www.google.com/recaptcha/api.js" />
        </main>
    )
}

export default QrLayout;